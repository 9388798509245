import {
    CheckCircleOutlined,
    EuroCircleOutlined,
    FileTextOutlined,
    HourglassOutlined,
    LoginOutlined,
    UserOutlined
} from '@ant-design/icons';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { User } from 'apis/types';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AppContext from 'layout/MainLayout/AppContext';
import MinimalLayout from 'layout/MinimalLayout';
import { WhitelabelledRenew } from 'layout/Whitelabelling/WhitelabelledRenew';
import { lazy, useContext, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { StateType } from 'store/reducers';
import { isAfter } from 'utils/dates';
import { Route } from './RoutesTypes';
import { FilterContextProvider } from 'pages/beetalk/Transcriptions/FilterProvider';
// ==============================|| ROUTING RENDER ||============================== //

// render - login
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthActivate = Loadable(lazy(() => import('pages/authentication/Activate')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthReset = Loadable(lazy(() => import('pages/authentication/Reset')));

// render
const Profile = Loadable(lazy(() => import('pages/beetalk/Profile')));
const Transcriptions = Loadable(lazy(() => import('pages/beetalk/Transcriptions')));
const Terms = Loadable(lazy(() => import('pages/beetalk/Terms')));
const Renew = Loadable(lazy(() => import('pages/beetalk/Renew')));
const ExpiredLicense = Loadable(lazy(() => import('pages/beetalk/ExpiredLicense')));

export const appRoutes = (t: TFunction<'translation', undefined>, user?: User): Route[] => {
    return [
        {
            requireAuth: true,
            children: [
                {
                    requireLicense: true,
                    requireTerms: true,
                    element: <Navigate to="/beetalk/transcriptions" />,
                    path: '*'
                },
                {
                    requireLicense: true,
                    requireTerms: true,
                    element: <Navigate to="/beetalk/transcriptions" />,
                    path: '/'
                }
            ]
        },
        {
            requireAuth: false,
            children: [
                {
                    element: <Navigate to="/login" />,
                    path: '*'
                },
                {
                    element: <Navigate to="/login" />,
                    path: '/'
                }
            ]
        },
        {
            title: t('nav_group_authentication'),
            children: [
                {
                    path: '/',
                    element: <MinimalLayout />,
                    children: [
                        {
                            id: 'register',
                            element: <AuthRegister />,
                            icon: AppRegistrationIcon,
                            path: 'register',
                            requireBeevoip: true,
                            requireAuth: false
                        },
                        {
                            id: 'activate',
                            element: <AuthActivate />,
                            path: 'activate',
                            requireAuth: false
                        },
                        {
                            id: 'login',
                            title: t('nav_item_login'),
                            element: <AuthLogin />,
                            icon: LoginOutlined,
                            path: 'login',
                            requireAuth: false
                        },
                        {
                            id: 'reset',
                            element: <AuthReset />,
                            path: 'reset',
                            requireAuth: false
                        }
                    ]
                }
            ]
        },
        {
            title: t('nav_group_portal'),
            requireAuth: true,
            requireLicense: true,
            requireTerms: true,
            children: [
                {
                    path: '/beetalk/',
                    element: <MainLayout />,
                    children: [
                        {
                            id: 'transcriptions',
                            title: t('nav_item_transcriptions'),
                            element: (
                                <FilterContextProvider>
                                    <Transcriptions />
                                </FilterContextProvider>
                            ),
                            icon: FileTextOutlined,
                            path: 'transcriptions'
                        }
                    ]
                }
            ]
        },
        ...(user && !user.hideRenewPage
            ? [
                  {
                      title: t('nav_group_payments'),
                      requireAuth: true,
                      children: [
                          {
                              path: '/license/',
                              element: <MainLayout />,
                              children: [
                                  {
                                      id: 'renew',
                                      title: t(!user || user.trial ? 'nav_item_subscribe_license' : 'nav_item_renew_license'),
                                      element: <WhitelabelledRenew />,
                                      icon: EuroCircleOutlined,
                                      path: 'renew'
                                  }
                              ]
                          }
                      ]
                  }
              ]
            : user
            ? [
                  {
                      requireAuth: true,
                      requireLicense: false,
                      path: '/license/',
                      element: <MainLayout />,
                      children: [
                          {
                              id: 'expired',
                              title: t('nav_license_expired'),
                              element: <ExpiredLicense />,
                              icon: HourglassOutlined,
                              path: 'expired'
                          }
                      ]
                  }
              ]
            : [])
        // Redirect finali di fallback nel caso il path non sia matchato da niente
    ];
};

const reduceRoutes = (routes: Route[], accessToken?: string, user?: User) => {
    return routes.reduce((accumulator, element) => {
        const route = Object.assign({}, element) as Route;
        const isLicenseValid = user?.expiryDate && isAfter(new Date(user.expiryDate));
        const didAcceptTerms = user?.condGdprSecurity && user?.condGeneralSale && user?.condPrivacyCookies && user?.condSpecialContract;
        if (
            // TRANSCRIBER-132
            // Se loggato, sia user che accessToken devono essere valorizzati, altrimenti ci sono situazioni in cui l'accessToken è valorizzato
            // ma lo user non è stato ancora caricato nel contesto, e reduceRoutes restituisce route sbagliati, con Navigate che vanno verso
            // pagine potenzialmente errate
            (!route.requireBeevoip || (process.env.REACT_APP_URL && window.location.href.startsWith(process.env.REACT_APP_URL))) &&
            ((user && accessToken && route.requireAuth !== false) || (!(user && accessToken) && !route.requireAuth)) &&
            ((isLicenseValid && route.requireLicense !== false) || (!isLicenseValid && !route.requireLicense)) &&
            ((didAcceptTerms && route.requireTerms !== false) || (!didAcceptTerms && !route.requireTerms))
        ) {
            delete route.requireBeevoip;
            delete route.requireAuth;
            delete route.requireLicense;
            delete route.requireTerms;
            if (route.children?.length) {
                route.children = reduceRoutes(route.children, accessToken, user);
            }
            if (route.path && !route.external) {
                accumulator.push(route);
            } else if (route.children?.length) {
                accumulator.push(...route.children);
            }
        }
        return accumulator;
    }, [] as Route[]);
};

const Routes: React.FC = (): React.ReactElement | null => {
    const [t] = useTranslation();
    const { accessToken } = useSelector((state: StateType) => state.auth);
    const { loggedUser } = useContext(AppContext);
    const location = useLocation();

    const routes = useMemo(() => {
        location;
        return reduceRoutes(appRoutes(t, loggedUser), accessToken, loggedUser);
    }, [location, loggedUser, t, accessToken]);

    return useRoutes(routes);
};

export default Routes;
