import { Box, Toolbar, useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { StateType } from 'store/reducers';
// types
import { openDrawer } from 'store/reducers/menu';
// project import
import InviteDialog from 'components/invitation/InviteDialog';
import Drawer from './Drawer';
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    const { accessToken } = useSelector((state: StateType) => state.auth);
    const { drawerOpen } = useSelector((state: StateType) => state.menu);

    const [open, setOpen] = useState(drawerOpen);

    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }, [dispatch, matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) {
            setOpen(drawerOpen);
        }
    }, [drawerOpen, open]);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    return (
        <>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                    <Toolbar sx={{ opacity: 0 }} />
                    {
                         //<Breadcrumbs navigation={routes} title titleBottom card={false} divider={false} />
                    }
                    <Outlet />
                </Box>
            </Box>
            <InviteDialog />
        </>
    );
};

export default MainLayout;
