import { FilterOptions } from 'apis/transcriptions';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

export type FilterContextType = FilterOptions & {
    setReportTypeId: Dispatch<SetStateAction<number | undefined>>;
    setOfficeId: Dispatch<SetStateAction<number | undefined>>;
};

export const FilterContext = createContext<FilterContextType>({
    setReportTypeId: () => {
        return;
    },
    setOfficeId: () => {
        return;
    }
});

export const FilterContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [reportTypeId, setReportTypeId] = useState<number | undefined>();
    const [officeId, setOfficeId] = useState<number | undefined>();

    return <FilterContext.Provider value={{ reportTypeId, officeId, setReportTypeId, setOfficeId }}>{children}</FilterContext.Provider>;
};
